import React from "react";
import Slider from "react-slick";
import { sliderData } from "./data";

const overViewCard = () => {
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  // });
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="overview-slider">
      <Slider {...sliderSettings}>
        {sliderData.map((item, index) => {
          return (
            <div key={index} className="overview-card">
              <img
                src={item.quoteImg}
                className="quotes-img"
                style={{ marginTop: `${item.marginTop}` }}
              />
              <div
                style={{ width: `${item.width}` }}
                className="overview-card-txt"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <img src={`${item.img}`} className="overview-card-img" />
              <div
                className="mobile-overview-profile"
                style={{ marginTop: index === 0 ? "-1em" : "" }}
              >
                <div className="reviwer-data">
                  <h3
                    className="reviewer-name"
                    dangerouslySetInnerHTML={{ __html: item.name }}
                  />
                  <p
                    className="reviwer-designation"
                    dangerouslySetInnerHTML={{ __html: item.design }}
                  />
                  <p
                    className="reviwer-edu"
                    dangerouslySetInnerHTML={{ __html: item.edu }}
                  />
                </div>
                <div className="mob-img-div">
                  <img
                    src={item.mobImg}
                    className="mob-reviwer-img"
                    style={{ marginLeft: `${item.marginleft}` }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default overViewCard;
