import React from "react";

const BenifitsTable = ({ tableName, setTable }) => {
  return (
    <div className="table-section">
      {tableName === "roi" && (
        <table className="roi">
          <tr>
            <th></th>
            <th>
              {/* <img
                className="basl_logo"
                src={bslpLogo}
                loading="lazy"
              /> */}
              <img
                className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yrnf1m92jms1p.png"
              />
            </th>
            <th>
              MBA
              <br /> (beyond top 20)
            </th>
            <th>
              {" "}
              Online Business & <br />
              Sales Programs{" "}
            </th>
          </tr>
          <tr>
            <td>Time Investment</td>
            <td>5 Months</td>
            <td>2 years</td>
            <td>7-12 Months</td>
          </tr>
          <tr>
            <td>Core Benefit</td>
            <td>
              ₹7.5-17L jobs + <br />
              Certification + Network
            </td>
            <td>
              Degree + <br /> ₹6-12L Jobs
            </td>
            <td>Certifications</td>
          </tr>
          <tr>
            <td>Fee</td>
            <td>₹2L</td>
            <td>₹6-15L</td>
            <td>₹3-7L</td>
          </tr>
          <tr>
            <td>Fee, if no job post completion</td>
            <td className="desk cell-hl">60% fee refunded if job &lt; ₹7.5L</td>
            <td className="mob cell-hl">
              60% fee refunded
              <br className="pc-hide" /> if job &lt; ₹7.5L
            </td>
            <td>No</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Cost of travel</td>
            <td className="borderBtm">
              No Cost as <br />
              program is online
            </td>
            <td className="desk">
              Full-Time Offline, <br />
              needs relocation
            </td>
            <td className="mob">
              Full-Time Offline, <br />
              needs relocation
            </td>
            <td>No</td>
          </tr>
        </table>
      )}
      {tableName === "placements" && (
        <table className="placements">
          <tr>
            <th></th>
            <th>
              <img
                className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yrnf1m92jms1p.png"
                loading="lazy"
              />
            </th>
            <th>
              MBA
              <br /> (beyond top 20)
            </th>
            <th>Online Business & Sales Programs</th>
          </tr>
          <tr>
            <td>Minimum CTC</td>
            <td>₹7.5L</td>
            <td>Not defined</td>
            <td>Not defined</td>
          </tr>
          <tr>
            <td>Highest CTC</td>
            <td>₹17.2L</td>
            <td>₹12L</td>
            <td>Not defined</td>
          </tr>
          <tr>
            <td>Placement Reports</td>
            <td>
              Published for all programs.
              <br />{" "}
              <a
                href="https://placement-reports.kraftshala.com/"
                target="_blank"
              >
                {" "}
                See Here
              </a>
            </td>
            <td>Partially</td>
            <td>Sometimes</td>
          </tr>
          <tr>
            <td>
              No. of Job
              <br /> Interviews
            </td>
            <td className="desk cell-hl">
              No upper cap - We keep going
              <br /> till you’re placed
            </td>
            <td className="mob cell-hl">
              No upper cap - We keep <br />
              going till you’re placed
            </td>
            <td>Nothing specified</td>
            <td>Nothing specified</td>
          </tr>
          <tr>
            <td>Kinds of Roles</td>
            <td className="borderBtm extra-bold">
              SaaS, B2B and FMCG Sales
              <br /> Roles in top-tier <br />
              companies
            </td>
            <td>
              <b>
                95% Field Sales roles
                <br /> 5% Marketing / Ops / Fin
              </b>
            </td>
            <td>Nothing specified</td>
          </tr>
        </table>
      )}
      {tableName === "community" && (
        <table className="community">
          <tr>
            <th></th>
            <th>
              <img
                className="basl_logo"
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yrnf1m92jms1p.png"
                loading="lazy"
              />
            </th>
            <th>
              MBA <br /> (beyond top 20)
            </th>
            <th>Online Business & Sales Programs</th>
          </tr>
          <tr>
            <td>Peer Group</td>
            <td>
              Smart peers
              <br /> Acceptance rate is 10%
            </td>
            <td className="desk">
              Smart peers who’ve <br />
              cleared CAT
            </td>
            <td className="mob" style={{ padding: 0 }}>
              Smart peers who’ve
              <br /> cleared CAT
            </td>
            <td className="desk">
              Anyone can enroll
              <br /> (no test)
            </td>
            <td className="mob">
              Anyone can <br />
              enroll (no test)
            </td>
          </tr>
          <tr>
            <td>Network</td>
            <td>
              Strong community of all
              <br /> B-Schools & Launchpad students
            </td>
            <td>Low-Medium</td>
            <td>Low</td>
          </tr>
          <tr>
            <td>Mentorship</td>
            <td className="borderBtm">
              Strong network across
              <br /> top companies
            </td>
            <td>Low-Medium</td>
            <td>Low</td>
          </tr>
        </table>
      )}
    </div>
  );
};

export default BenifitsTable;
