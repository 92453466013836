export const sliderData = [
  {
    id: 1,
    description: `I was very confused whether I should go for an MBA. If i didn't get an IIM, then ROI is not good.<br/>
Kraftshala was offering <b>much better ROI on my time and money</b>. 2 of my friends were already placed in large companies from them. 
<br/><br/>
I am now placed at <img class="pgp-txt-img" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86ykn1mm71xsx5e.png" alt="" /><br/>
 <b>which was a dream company <b> for me!`,
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82tk1ll.webp",
    width: "415px",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ar142y.png",
    name: `Abhishek <br/> Nimawat`,
    design: `Sales Executive trainee, Nestlé`,
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8sabpve.webp",
    edu: `Ex-Biotech graduate`,
    quoteImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8rcxzr1.png",
    marginTop: "4em",
  },

  {
    id: 2,
    description: `I was a fresher. And in the B2B industry, 
    if you need a job you need to have experience but to have 
    experience you need a job. It's like a vicious cycle and that's 
    what Kraftshala’s program helped me break. For me, it wasn’t just a program, it was rather a<b> series of projects </b>which helped me gain <b>real work experience</b>.<br/><br/>
    I got placed at <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86ykseam72u27xe.png" class="pgp-txt-img" /> &nbsp; in a <b>remote role</b>. I don't have any freshers working alongside me. So I am still amazed that I could 
    break in!`,
    width: "520px",
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82to3p3.webp",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6aqvv16.png",
    name: `Deeksha <br/> Yadav`,
    design: `Sales Development Representative, <br/> Browserstack`,
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8safx9t.webp",
    edu: `Fresher`,
    marginleft: "0.5em",
    quoteImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8rcxzr1.png",
  },
  {
    id: 3,
    description: `I am a Science graduate from 2023 and joined a BPO and I LOVED <b>talking to people</b>. Thats how i realised i want to be in sales and got into Kraftshala’s program. <br/><br/> And what a decision it was! <br/> I got placed at  <img class="pgp-txt-img" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86ykn1mm71xsx5e.png" alt="" /> &nbsp;where I now take care of  a full territory! I am responsible for new business development, managing the team from the DS, to the merchandiser and driving revenue. It is a <b>dream to be here at Nestle</b> and I am enjoying each moment.`,
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82uzepw.webp",
    width: "482px",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m73fagox.png",
    name: `Kritika <br/> Yadav`,
    design: `Sales Executive trainee, Nestlé`,
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8saulae.webp",
    edu: `Ex-Subject Matter Expert <br/> @Task Us`,
    quoteImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8rcxzr1.png",
  },
  {
    id: 4,
    description: `I joined the program to enhance my business knowledge, and it was absolutely worth it. The curriculum is ‘too practical,’ taught by professionals working in the industry. The rigorous structure kept me on my toes, and the <b>live projects were a game-changer</b>—calling 300 real customers, facing rejections, and finally cracking conversions was an unmatched experience. 
    The mentorship, real-world exposure, and placements made this <b>better than an MBA for sure</b>! It was learning by doing, and it changed everything for me!`,
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82v2aj9.webp",
    width: "530px",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6aqvv16.png",
    name: `Niyati <br/> Madhavani`,
    design: `Business Manager - Enterprise <br/> Sales, Analytics Vidya`,
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8san412.webp",
    edu: `Ex-Content Writer`,
    quoteImg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8rcxzr1.png",
  },
];

export const MobilesliderData = [
  {
    id: 1,
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6jbiply.png",
  },
  {
    id: 2,
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6kczjhk.png",
  },
];
