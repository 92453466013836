import React, { useState, useEffect } from "react";
import "./styles.scss";

const index = () => {
  const [muted, setMuted] = useState(true);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll(".hover-video")[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleMouseLeave = (index, video) => {
    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  const handleClick = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll(".hover-video")[index];

    if (video) {
      setMuted((prevMuted) => !prevMuted); // Toggle mute state
      video.play();
    }
  };

  // Highlighted: Added touch event handlers
  const handleTouchStart = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll(".hover-video")[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleTouchEnd = (index) => {
    const video = document.querySelectorAll(".hover-video")[index];

    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  useEffect(() => {
    const videos = document.querySelectorAll(".hover-video");

    videos.forEach((video) => {
      video.muted = muted;
    });
  }, [muted]);
  const thumbnails = [
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yq767m8r6ol1z.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yq767m8r6vzt3.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yq767m8r70miy.webp",
  ];
  const AllvideoSources = [
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ezg814.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ezhp6w.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6eziu9i.mp4",
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [finalSlide, setfinalSlide] = useState(false);
  const handlePrev = () => {
    if (currentSlide > 0) {
      if (window.innerWidth > 800) setCurrentSlide(-1);
      else setCurrentSlide(currentSlide - 1);

      setfinalSlide(false);
    }
  };

  const handleNext = () => {
    const maxSlides = 4 - (window.innerWidth <= 800 ? 2 : 3);

    if (finalSlide) {
      return;
    }

    if (currentSlide < maxSlides) {
      if (currentSlide === -1) {
        setCurrentSlide(currentSlide + 2);

        if (window.innerWidth > 800) setfinalSlide(true);

        return;
      }

      setCurrentSlide(currentSlide + 1);

      if (!(currentSlide + 1 < maxSlides)) {
        setfinalSlide(true);
      } else setfinalSlide(false);
    }
  };

  useEffect(() => {
    const cardContainer = document.querySelector(".sales-card-container-2");
    const slideWidth = window.innerWidth <= 800 ? 190 : 300 * 3 + 2 * 3; // Adjust slide width based on screen size

    cardContainer.scrollLeft = currentSlide * slideWidth;
  }, [currentSlide]);

  return (
    <div className="basl-sales">
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulx1ld.png"
        loading="lazy"
        className="pc-sales-dots-right"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulza3r.png"
        loading="lazy"
        className="pc-sales-dots-left"
      />
      <div className="text-content">
        <div className="sales-sub-header">WHY SALES?</div>
        <h2 className="sales-header">
          Sales - A career of <br className="pc-hide" /> champions
        </h2>
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6t2ftbf.png"
          loading="lazy"
          className="salesUndeline"
        />
      </div>

      {/* buttons area */}
      <div className="why-sales-btn-container">
        <button
          className="why-sales-prevBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8r8s2v5.png')",
            opacity: currentSlide <= 0 ? 0.2 : 1,
          }}
          onClick={handlePrev}
        ></button>
        <button
          className="why-sales-nxtBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8r8sxx3.png')",
            opacity: finalSlide ? 0.2 : 1,
          }}
          onClick={handleNext}
        ></button>
      </div>

      <div className="sales-card-container-2">
        <div className="sales-card-container">
          <div className="sales-card gaurav">
            <a
            // href="https://www.linkedin.com/in/gauravrastogi/"
            // target="_blank"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqbk4m8r7kgqh.webp"
                loading="lazy"
                className="salesImage"
              />
              <img
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uknpuh.png"
                loading="lazy"
                className="salesAlt"
              />
            </a>
          </div>

          <div className="newsales--container">
            <div className="newsales">
              {thumbnails.map((thumbnail, index) => (
                <div className="trench" key={index}>
                  {/* <div className="reel-container"> */}
                  <video
                    className={`hover-video ${
                      playingVideoIndex === index ? "show" : "hide"
                    }`}
                    width="350"
                    height="360"
                    onMouseLeave={(e) => handleMouseLeave(index, e.target)}
                    // onClick={handleClick}
                    onClick={() => handleClick(index)}
                    onTouchStart={() => handleTouchStart(index)}
                    onTouchEnd={() => handleTouchEnd(index)}
                    muted={muted}
                    playsInline
                  >
                    <source src={AllvideoSources[index]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                  <img
                    src={thumbnail}
                    alt={`thumbnail-${index}`}
                    className={`thumbnail ${
                      playingVideoIndex === index ? "hide" : "show"
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                  />
                  {/* </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
