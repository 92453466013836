import React, { Fragment } from "react";
import "./styles.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import OverviewCard from "./overViewCard";

function Overview() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const scrollTo = (direction) => {
    let node = document.querySelector(".grid-container.pc-none");

    if (node) {
      let scrollWidth = direction === "left" ? node.scrollWidth : 0;

      node.scrollLeft = scrollWidth;
    }
  };

  return (
    <>
      <div className="overview-bsl">
        <div className="overview-container-main">
          <div className="overview-sub-title">GET Better Results, Faster!</div>
          <h2 className="overview-title">
            Higher ROI than 99.6% of all MBA programs
          </h2>
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m78v6zpq.png"
            className="overview-underline"
            loading="lazy"
          />
        </div>
        <div className="grid-container mobile-none" ref={ref}>
          <div className="index">
            <div className="head"></div>
            <div className="today index-today">
              <div className="day"> Today</div>
              <div className="day year2025"> 2025</div>
              <div className="day"> 2026</div>
            </div>
            <div className="roi bordered">
              Roi
              <br />
              <div className="subRoi"> (Salary-Fee) / Fee*100</div>
            </div>
            <div className="roi">Time to deliver ROI</div>
          </div>
          <div className="bslp">
            <div className="head">
              <img
                src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yrnf1m92bias3.jpg"
                loading="lazy"
                className="baslLogo"
              />
            </div>
            {inView && (
              <>
                <div className="today">
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.25 }}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82spha9.webp"
                      loading="lazy"
                      className="yellowstrip"
                    />
                  </motion.Fragment>
                </div>
                <div className="roi bordered">
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.5 }}
                    className="roi-fee"
                  >
                    <div className="greenText">400%</div> (Fee ₹2L)
                  </motion.Fragment>
                </div>
                <div className="roi">
                  {" "}
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.8 }}
                  >
                    &lt;1 year{" "}
                  </motion.Fragment>{" "}
                </div>
              </>
            )}
          </div>
          <div className="mbas">
            <div className="head">MBAs (except top 20)</div>
            {inView && (
              <>
                <div className="today">
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.25 }}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82sfjg1.webp"
                      loading="lazy"
                      className="greystrip"
                    />
                  </motion.Fragment>
                </div>
                <div className="roi bordered">
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.5 }}
                    className="roi-fee"
                  >
                    <div className="redText"> -41%</div> (Fee ₹12L)
                  </motion.Fragment>
                </div>

                <div className="roi">
                  <motion.Fragment
                    variants={{
                      hidden: { opacity: 0, y: 70 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.55, delay: 0.8 }}
                  >
                    {" "}
                    2 year{" "}
                  </motion.Fragment>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="grid-container pc-none">
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yrnf1m92grly4.webp"
            className="placementTableMob"
            loading="lazy"
            onClick={() => scrollTo("left")}
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm835y15y.webp"
            className="placementTableMob-2"
            loading="lazy"
            onClick={() => scrollTo("right")}
          />
        </div>
        <OverviewCard />
      </div>
    </>
  );
}

export default Overview;
