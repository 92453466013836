import React, { useState } from "react";
import "./styles.scss";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const MainHero = () => {
  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "40px",
    dotsClass: "slick-dots custom-dots",
    appendDots: (dots) => <ul>{dots}</ul>,
    swipe: true, // Enable touch swipe
    swipeToSlide: true, // Enable swipe to slide behavior
    touchThreshold: 10, // Make swiping more sensitive (default is 5)
    draggable: true, // Enable mouse drag
  };
  const handleScrollToHero = () => {
    const heroSection = document.getElementById("hero-div");

    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [front, setFront] = useState(true);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Course",
            name: "PGP in Sales and Business Leadership",
            description:
              "The PGP in Sales and Business Leadership by Kraftshala is India's best online sales course with placements starting from 7.5 LPA up to 17 LPA in top companies. It's a 5 months full-time sales program with 4 live projects with real brands and full-time jobs.",
            provider: {
              "@type": "Organization",
              name: "Kraftshala",
              url: "https://www.kraftshala.com/",
            },
            url: "https://www.kraftshala.com/sales-course/",
            image:
              "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6oyo9do.png",
            category: "Sales and Business",
            offers: {
              "@type": "Offer",
              price: "200000",
              priceCurrency: "INR",
              availability: "https://schema.org/InStock",
              url: "https://www.kraftshala.com/sales-course/",
              category: "Sales and Business",
            },
            hasCourseInstance: [
              {
                "@type": "CourseInstance",
                courseMode: "Online",
                duration: "P19W",
                courseSchedule: {
                  "@type": "Schedule",
                  repeatCount: 1,
                  repeatFrequency: "Monthly",
                  description: "Full-time, with live online classes",
                },
              },
            ],
          })}
        </script>
      </Helmet>

      <div className="basl-main-hero-wrapper-pc">
        <div className="left-hero-div">
          <div className="content-section">
            <div className="text-area">
              <div className="pgp-header">
                PGP in Sales and <br /> Business Leadership
                <Link to="/">
                  <img
                    className="pgp-logo-bird"
                    src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82oah2d.webp"
                    alt=""
                  />
                </Link>
              </div>
              <div className="intustry-cred">
                with INDUSTRYCreds<sup>®</sup> Certification
              </div>
              <h1 className="pgp-banner-txt-head">
                <span className="sales-ylo">#1</span> Sales & Business School
                for Top-Tier Roles
              </h1>
              <span className="pgp-banner-txt">
                <img
                  className="icon-1"
                  src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z6uz85.png"
                  alt=""
                />
                <span className="pgp-banner-txt">₹17.2L Highest CTC</span>
                <br className="pc-none" />
                <img
                  className="icon-2"
                  src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z6tf7r.png"
                  alt=""
                />
                <span className="pgp-banner-txt mob-mx">
                  94% Placement Rate
                </span>
              </span>
              <div className="real-accountability">
                <img
                  className="icon-3"
                  src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z6w4w5.png"
                />

                <span className="pgp-banner-txt mtop-half mobile-none">
                  Real accountability- If the job {"<"} ₹7.5L, we refund 60%
                </span>
                <span className="pgp-banner-txt mtop-half pc-none">
                  If the job {"<"} ₹7.5L, we refund 60%
                </span>
              </div>
            </div>
            <div className="buttons-area">
              <button className="Apply-now-btn" onClick={handleScrollToHero}>
                Apply now
              </button>
              <button className="dowld-brochure" onClick={handleScrollToHero}>
                Download Brochure
              </button>
            </div>
            <div className="hiring-partners-area mobile-hide">
              <div
                className="hiring-partners-wrapper"
                onMouseEnter={() => setFront(false)}
                onMouseLeave={() => setFront(true)}
                onTouchStart={() => setFront(false)}
                onTouchEnd={() => setFront(true)}
              >
                {front ? (
                  <div className="hiring-partners front">
                    <p className="hiring-text-pc">
                      Hiring <br />
                      partners
                    </p>
                    <div className="hiring-part-line-pc"></div>
                    <div className="img-container-pc">
                      <img
                        style={{ height: "43px", width: "43px" }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8j5ep.webp"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{ height: "37px", width: "73px" }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8mt45.webp"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{ height: "47px", width: "84px" }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8o7nd.webp"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{ height: "15px", width: "77.66px" }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8yz38.png"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="hiring-partners back">
                    <p className="hiring-text-pc">
                      Hiring <br />
                      partners
                    </p>
                    <div className="hiring-part-line-pc"></div>
                    <div className="img-container-pc">
                      <img
                        style={{ height: "22px", width: "49px" }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h91tnv.png"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{
                          height: "29px",
                          width: "64px",
                          marginTop: "3px",
                        }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h935p0.png"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{
                          height: "14.5px",
                          width: "70px",
                          marginTop: "2px",
                        }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h949pg.png"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                      <img
                        style={{
                          height: "39px",
                          width: "68px",
                          marginBottom: "11px",
                        }}
                        className="hirin-part-sbl-img"
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h95gey.webp"
                        alt="hiring-partner-img"
                        rel="preload"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="right-hero-div">
          <div className="images-section">
            <img
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82pfn6i.webp"
              alt="herosec-image"
            />
          </div>
        </div>
      </div>

      <div className="basl-main-hero-wrapper-mob">
        <div className="buttons-div-mob">
          <button className="Apply-now-btn-mob" onClick={handleScrollToHero}>
            Apply now
          </button>
          {/* <button className="dowld-brochure-mob" onClick={handleScrollToHero}>
            Download Brochure
          </button> */}
        </div>
        {/* <div className="slider-div-mob">
          <Slider {...settings}>
            {data.map((info, key) => (
              <div className="card-hero-slider" key={key}>
                <img src={info.CardImg} alt="slider-icons" />
              </div>
            ))}
          </Slider>
        </div> */}
      </div>
    </>
  );
};

export default MainHero;
