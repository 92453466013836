export const data = [
  {
    id: 0,
    title: "Strategic Business Pillars",
    desc:
      "You learn the fundamentals of commercial functions in any business across industries. You go deeper into Category Drivers, User Understanding, Business Opportunity Identification, Go-to-Market Strategies, Operations Management, Financial Intelligence (P&L and Cash Flow Management). Goal is to help you understand how to drive overall business health.",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82vqbm5.webp",
  },
  {
    id: 1,
    title: "Sales and Persuasion Strategies",
    desc:
      "You’ll learn how sales involves moving others. You master Kraftshala’s framework - The Sales Bridge - for building trust, gathering intelligence, probing, problem solving and objection handling.",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82vs8g4.webp",
  },
  {
    id: 2,
    title: "B2B Sales Excellence: SaaS",
    desc:
      "You master the end-to-end process of a B2B SAAS Sales cycle. From prospecting, lead generation & qualification, strategic research, cold calling and emailing, conducting demos, leveraging multiple tools and more. You learn all of it from cases and insights from some of India’s best practitioners.",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82vv7o3.webp",
  },
  {
    id: 3,
    title: "B2C:FMCG Sales Excellence",
    desc:
      "You get into the details of the B2C: FMCG sector by learning about distribution structures, Category Management (including Modern trade, Q-comm, Ecomm), Distributor, ROI & Credit Management, Trade & Marketing plans, and reporting",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82vx6je.webp",
  },
  {
    id: 4,
    title: "Better Problem Solving",
    desc:
      "View the world through the lens of problems to be solved. Using case studies to internalize Kraftshala’s DIG DEEP principles to solve problems rigorously, systematically and quickly, esp in your work context. Use Excel as a problem solving tool to enable data driven decision making.",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82vzlb1.webp",
  },
  {
    id: 5,
    title: "Human Skills",
    desc:
      "Here we learn transferable skills which will form the basis of your success at your workplaces - impactful and empathetic communication, work product training, building a digital presence, active listening and building work ethics to drive behavior change and strong reflection skills.",
    image:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82w38ls.webp",
  },
];
